"use client";

import { useEffect } from "react";

import { removeVisitId } from "./utils";

// Using component to remove, to import this on the base layout.tsx (server component)
export default function RemoveTrackerVisitId() {
  useEffect(() => {
    // Remove visitId every close or verify for tracking purpose
    return () => removeVisitId();
  }, []);

  return null;
}
